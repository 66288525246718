import React, { useRef, useState } from 'react';
import { addDoc, collection, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { formatCurrency } from '../../Help/helper';
import Logo from "../../../images/bigAyden.jpg";
import './QuotationModalPrint.css';
import { useAuth } from '../../Auth/Auth';
import { toast, ToastContainer } from 'react-toastify';
import { TermsAndConditions } from './TermsAndConditions';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const QuotationModal = ({ isOpen, onClose, customerDetails, quotationDetails, totals, newPart, selectedJob }) => {
  const { user } = useAuth(); 
  const currentUser = user?.email;
  const [isSaving, setIsSaving] = useState(false); 
  const printRef = useRef(); 
  if (!isOpen) return null; 

  const itemsToDisplay = Array.isArray(quotationDetails.items) ? quotationDetails.items : [];
  if (newPart && newPart.partName && newPart.salePrice) {
    itemsToDisplay.push(newPart);
  }

  const handleGeneratePDF = () => {
    const input = printRef.current;
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Quotation_${selectedJob?.jobCardNo}.pdf`);
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  const handleSaveAndPrint = async () => {
    if (isSaving) return; 
    setIsSaving(true); 

    const validCartItems = quotationDetails.items.filter(item =>
      item.partName && !isNaN(item.salePrice) && !isNaN(item.quantity) && !isNaN(item.costPrice)
    );
    
    const formattedCartItems = validCartItems.map(item => ({
      description: item.partName,
      quantity: parseInt(item.quantity, 10),
      costPrice: parseFloat(item.costPrice),
      salePrice: parseFloat(item.salePrice),
      extendedPrice: parseFloat(item.salePrice) * parseInt(item.quantity, 10)
    }));
    
    if (newPart && newPart.partName && !isNaN(newPart.salePrice) && !isNaN(newPart.quantity) && !isNaN(newPart.costPrice)) {
      const formattedNewPart = {
        description: newPart.partName,
        quantity: parseInt(newPart.quantity, 10),
        costPrice: parseFloat(newPart.costPrice),
        salePrice: parseFloat(newPart.salePrice),
        extendedPrice: parseFloat(newPart.salePrice) * parseInt(newPart.quantity, 10)
      };
      formattedCartItems.push(formattedNewPart);
    }
    
    const quotationData = {
      approvalStatus: "No",
      cartItems: formattedCartItems,
      totalCost: parseFloat(totals.totalCost),
      subtotal: parseFloat(totals.subtotal),
      totalSale: parseFloat(totals.totalSale),
      taxAmount: parseFloat(totals.taxAmount),
      customerEmail: customerDetails.email,
      customerName: customerDetails.name,
      customerAddress: customerDetails.address,
      createdBy: currentUser,
      jobCardNo: selectedJob?.jobCardNo,
      modifiedBy: "",
      dateCreated: new Date(),
    };

    try {
      // Save to Firestore
      const savedDocumentId = await saveQuotationToFirestore(quotationData);
      
      if (savedDocumentId) {
        // Update the tracker field in the jobs collection
        const updateResult = await updateJobTracker(selectedJob.jobCardNo);
        if (updateResult.success) {
          handleGeneratePDF(); // Generate PDF
        } else {
          toast.warn(updateResult.message);
        }
      }
    } catch (error) {
      console.error('Error during saving and updating: ', error);
      toast.error('Error during saving and updating.');
    } finally {
      setIsSaving(false); // Re-enable the button
    }
  };
  
  const saveQuotationToFirestore = async (quotationData) => {
    try {
      const docRef = await addDoc(collection(db, 'quotations'), quotationData);
      toast.success('Quotation saved ');
      return docRef.id;
    } catch (e) {
      console.error('Error saving quotation: ', e);
      toast.error('Error saving quotation.');
    }
  };

  const updateJobTracker = async (jobCardNo) => {
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('jobCardNo', '==', jobCardNo));
      const querySnapshot = await getDocs(jobsQuery);
  
      if (querySnapshot.empty) {
        return { success: false, message: 'No matching job found.' };
      }
  
      querySnapshot.forEach(async (docSnapshot) => {
        const newTrackerEntry = {
          date: new Date(),
          state: "Quotation Raised"
        };
  
        const jobDocRef = doc(db, 'jobs', docSnapshot.id);
        await updateDoc(jobDocRef, {
          tracker: [...docSnapshot.data().tracker, newTrackerEntry]
        });
      });
  
      return { success: true, message: 'Job tracker updated successfully.' };
    } catch (e) {
      console.error('Error updating job tracker: ', e);
      return { success: false, message: 'Error updating job tracker.' };
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full print:bg-white" id="my-modal">
      <div className="relative top-10 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white print:shadow-none">
        {/* This div will be printed */}
        <div ref={printRef}>
          {/* Modal Header */}
          <div className="flex justify-between items-start p-2">
            <div className="flex flex-col justify-between">
              <div className="flex">
                <img src={Logo} alt="Logo" className="w-40 h-40 object-contain" />
              </div>
              <div className='ml-2'>
                <h2 className="text-3xl leading-6 font-medium uppercase mb-1 text-blue-800 ">Quotation</h2>
                <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Job Card No:</span> {selectedJob?.jobCardNo}</p>
                <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Date:</span> {new Date().toLocaleDateString()}</p>
              </div>
            </div>
            <div className="text-right">
              <h2 className="text-2xl font-bold">AYDENS Garage</h2>
              <p className="text-sm">Block 7, Plot 7, Etal Avenue, Off Kudirat Abiola Way,<br/> Oregun, Lagos, Nigeria.</p>
              <p className="text-sm">+234-7045972414</p>
              <p className="text-sm">info@aydensgarage.com</p>
            </div>
          </div>

          {/* Customer Details */}
          <div className="mt-2 mb-4 px-4">
            <h3 className="leading-6 font-medium text-gray-900">CUSTOMER:</h3>
            <p className="text-sm">{customerDetails.name}</p>
            <p className="text-sm">{customerDetails.address}</p>
            <p className="text-sm">{customerDetails.email || 'email@example.com'}</p>
            <p className="text-sm">{selectedJob.vehicleMake} {selectedJob.vehicleModel} ({selectedJob.vehicleYear}) - {selectedJob.registrationNumber} </p>
          </div>
                  
          {/* Quotation Table */}
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Item
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Price
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Extended Price
                </th>
              </tr>
            </thead>
            <tbody>
              {itemsToDisplay.map((item, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center">
                    {item.partName}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center">
                    {item.quantity}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center">
                    {formatCurrency(item.salePrice)}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center">
                    {formatCurrency(item.salePrice * item.quantity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Totals */}
          <div className="mt-4 text-right pr-20">
            <p className="text-sm text-gray-900 font-semibold">
              Subtotal: {formatCurrency(totals.subtotal)}
            </p>
            <p className="text-sm text-gray-500">
              Tax: {formatCurrency(totals.taxAmount)}
            </p>
            <p className="text-lg text-gray-900 font-bold">
              Total: {formatCurrency(totals.totalSale)}
            </p>
          </div>

          {/* Terms and Conditions */}
          <TermsAndConditions />
        </div>

        {/* Buttons */}
        <div className="items-center px-4 py-3 print:hidden">
          <button
            id="ok-btn"
            className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={onClose}
          >
            Close
          </button>
          <button
            id="save-and-print-btn"
            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 mt-2"
            onClick={handleSaveAndPrint}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save and Print'}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default QuotationModal;
