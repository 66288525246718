import React from 'react';
import { Link } from 'react-router-dom';

const HelpCenterPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <header className="bg-white border-b shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <h1 className="text-2xl font-bold text-gray-900">AYGEN Garage Management System Help Center</h1>
          </div>
        </div>
      </header>

      {/* Search Bar */}
      <div className="bg-gray-100 py-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900">How can we help you?</h2>
          <div className="mt-4">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Search for topics or articles..."
            />
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <section className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <h3 className="text-xl font-bold text-gray-900 mb-6">Browse by Category</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <CategoryCard title="Getting Started" description="Learn how to get started with our services." />
          <CategoryCard title="Account Management" description="Manage your account settings and preferences." />
          <CategoryCard title="Billing & Payments" description="Understand billing, invoices, and payments." />
        </div>
      </section>

      {/* Popular Articles Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h3 className="text-xl font-bold text-gray-900 mb-6">Popular Articles</h3>
          <ul className="space-y-4">
            <ArticleLink title="How to reset your password" id="reset-password" />
            <ArticleLink title="Understanding your invoice" id="invoice" />
            <ArticleLink title="How to update your profile information" id="update-profile" />
          </ul>
        </div>
      </section>
    </div>
  );
};

const CategoryCard = ({ title, description }) => {
  return (
    <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
      <h4 className="text-lg font-bold text-gray-900">{title}</h4>
      <p className="mt-2 text-gray-600">{description}</p>
    </div>
  );
};

const ArticleLink = ({ title, id }) => {
  return (
    <li>
      <Link to={`/help/article/${id}`} className="text-lg text-blue-600 hover:underline">
        {title}
      </Link>
    </li>
  );
};

export default HelpCenterPage;
